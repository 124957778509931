import 'ag-grid-community/dist/styles/ag-grid.css';

import './src/theme/index.less';

import './src/theme/chart.scss';
import './src/theme/grid.scss';

// Enable redux dev tools for development
import { akitaDevtools } from '@datorama/akita';
if (process.env.NODE_ENV !== 'production') {
  akitaDevtools();
}
